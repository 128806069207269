<template>
  <div class="body-bcg text-left">
    <a-breadcrumb class="breadcrum">
      <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
        <a-breadcrumb-item>
          <router-link to="/">{{
            client ? client.ClientName : ""
          }}</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item
          ><router-link to="/update-profile"
            >My Profile</router-link
          ></a-breadcrumb-item
        >
      </template>
    </a-breadcrumb>

    <div class="page-padding">
      <div class="page-title">
        <icon-base
          :width="35"
          :height="36"
          :viewBox1="24"
          :viewBox2="24"
          iconName="user"
          iconColor="transparent"
          iconStroke="#4318FF"
          ><icon-user
        /></icon-base>
        <div class="page-title__text">
          <h1>My Profile</h1>
          <span>Profile Details</span>
        </div>
      </div>

      <div class="box-wrapper box-wrapper--settings-form mb-25 max-w-970">
        <h2 class="mb-25">General information</h2>
        <div class="box-wrapper__inner">
          <div class="input-group">
            <label class="lineLable">First Name*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
              <a-input placeholder="" v-model="profileInfo.firstName" />
            </div>
          </div>
          <div class="input-group">
            <label class="lineLable">Last Name*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-input placeholder="" v-model="profileInfo.lastName" />
            </div>
          </div>

          <div class="input-group">
            <label class="lineLable">Email*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-input
                placeholder=""
                v-model="profileInfo.EmailId"
                :class="{
                  error: userInformationsValidation.length > 0,
                }"
              />
            </div>
          </div>
          <div class="input-group">
            <label class="lineLable">Company Name*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-input placeholder="" v-model="profileInfo.CompanyName" />
            </div>
          </div>

          <div class="input-group">
            <label class="lineLable">Job title*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-input placeholder="" v-model="profileInfo.jobTitle" />
            </div>
          </div>
          <div class="input-group">
            <label class="lineLable">Country*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-select
                class="fullWidth"
                option-filter-prop="children"
                show-search
                :default-value="profileInfo.country"
                :key="selectDropdownsKey"
                @change="selectCountry"
              >
                <a-select-option
                  v-for="country in countries"
                  :key="country.MarketId"
                  :value="country.MarketName"
                >
                  {{ country.MarketName }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </div>

      <div class="max-w-970 flex justify-between mb-40">
        <div>
          <ul
            class="validation-list"
            v-if="userInformationsValidation.length > 0"
          >
            <li
              v-for="(item, index) in userInformationsValidation"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="flex justify-end">
          <a-button
            type="primary"
            :disabled="saveChangesBtn"
            @click="userDataCheck"
          >
            Save Changes
          </a-button>
        </div>
      </div>

      <div
        class="
          box-wrapper box-wrapper--settings-form box-wrapper--3-cols
          mb-25
          max-w-970
        "
      >
        <h2 class="mb-25">Change password</h2>
        <div class="box-wrapper__inner">
          <div class="input-group">
            <label class="lineLable">Current Password*</label
            ><a-input
              placeholder=""
              type="password"
              :class="{ error: passwordValidation.length > 0 }"
              v-model="currentPassword"
            />
          </div>

          <div class="input-group">
            <label class="lineLable">New Password*</label
            ><a-input
              placeholder=""
              type="password"
              v-model="newPassword"
              :class="{ error: passwordValidation.length > 0 }"
            />
          </div>
          <div class="input-group">
            <label class="lineLable">Repeat New Password*</label
            ><a-input
              placeholder=""
              type="password"
              v-model="repeatPassword"
              :class="{ error: passwordValidation.length > 0 }"
            />
          </div>
        </div>
      </div>

      <div class="max-w-970 flex justify-between">
        <div>
          <ul class="validation-list" v-if="passwordValidation.length > 0">
            <li v-for="(item, index) in passwordValidation" :key="index">
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="flex justify-end">
          <a-button
            type="primary"
            :disabled="!disableSavePasswordBtn"
            @click="passwordCheck"
          >
            Save Password
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import _ from "lodash";
import Skeleton from "@/components/general/Skeleton.vue";
import IconBase from "@/components/general/IconBase.vue";
import IconUser from "@/components/icons/IconUser.vue";

export default {
  name: "UpdateProfile",
  components: {
    Skeleton,
    IconBase,
    IconUser,
  },
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      user: null,
      client: null,
      profileInfo: {
        firstName: "",
        lastName: "",
        EmailId: "",
        CompanyName: "",
        jobTitle: "",
        country: "",
      },
      activeProfileInfo: null,
      saveChangesBtn: false,
      countries: [],
      selectDropdownsKey: 0,
      repeatPassword: null,
      currentPassword: null,
      newPassword: null,
      passwordValidation: [],
      userInformationsValidation: [],
      informationsSuccessUpdated: false,
      passwordSuccessUpdated: false,
    };
  },
  watch: {
    profileInfo: {
      handler(newVal) {
        this.saveChangesBtn = _.isEqual(newVal, this.activeProfileInfo);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    selectCountry(value) {
      this.profileInfo.country = value;
    },
    userDataCheck() {
      const data = {
        firstName: this.profileInfo.firstName,
        lastName: this.profileInfo.lastName,
        EmailId: this.profileInfo.EmailId,
        CompanyName: this.profileInfo.CompanyName,
        jobTitle: this.profileInfo.jobTitle,
        country: this.profileInfo.country,
      };
      const emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.userInformationsValidation = [];
      if (this.profileInfo.EmailId !== this.activeProfileInfo.EmailId) {
        if (emailFormat.test(this.profileInfo.EmailId)) {
          this.updateUserData(data, "informationsSuccessUpdated");
        } else {
          this.userInformationsValidation.push(
            "The email address you entered is not valid!"
          );
        }
      } else {
        this.updateUserData(data, "informationsSuccessUpdated");
      }
    },

    updateUserData(data, dataType) {
      const userData = {
        userID: this.user.EncrypteduserID,
        ...data,
      };
      const updateUserData = new Promise((resolve) => {
        wsUtils.UpdateUserData(userData, resolve);
      });

      updateUserData.then((data) => {
        if (!data.Success && data.Status === 2) {
          this.passwordValidation.push("Wrong Current Password!");
        }
        if (data.Success && data.Status === 1) {
          this.$notification["success"]({
            message: `Well done!`,
            description: "Information is successfully updated.",
            placement: "bottomLeft",
            duration: 6,
          });

          if (this.profileInfo.EmailId !== this.activeProfileInfo.EmailId) {
            setTimeout(() => {
              jsUtils.clearSetting();
              this.account = {};
              this.$router.push("/login");
            }, 2000);
          } else {
            this.getUserData();
          }
        }
        if (dataType === "passwordSuccessUpdated") {
          this.repeatPassword = null;
          this.currentPassword = null;
          this.newPassword = null;
        }
      });
    },
    passwordCheck() {
      const data = {
        CurrentPassword: this.currentPassword,
        NewPassword: this.newPassword,
      };

      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
      this.passwordValidation = [];
      if (this.newPassword.length < 8)
        this.passwordValidation.push("Must be atleast 8 characters!");
      if (!/\d/.test(this.newPassword))
        this.passwordValidation.push("Must contain atleast 1 number!");
      if (!/[A-Z]/.test(this.newPassword))
        this.passwordValidation.push("Must contain atleast 1 Capital Case!");
      if (!format.test(this.newPassword))
        this.passwordValidation.push(
          "Must contain atleast 1 Special Character!"
        );
      if (this.newPassword !== this.repeatPassword)
        this.passwordValidation.push(
          "New Password and Repeat Password do not match"
        );

      if (this.passwordValidation.length === 0) {
        this.updateUserData(data, "passwordSuccessUpdated");
      }
    },
    getUserData() {
      const userInfo = new Promise((resolve) => {
        wsUtils.GetUserInfo(this.user.EncrypteduserID, resolve);
      });

      userInfo.then((data) => {
        this.profileInfo = data[0];
        this.activeProfileInfo = Object.assign({}, data[0]);
        this.selectDropdownsKey = 2;
      });

      const countryPromise = new Promise((resolve) => {
        wsUtils.MarketList(resolve);
      });

      countryPromise.then((data) => {
        this.countries = data;
        this.selectDropdownsKey = 1;
      });
    },
  },
  computed: {
    disableSavePasswordBtn() {
      return (
        this.CurrentPassword !== null &&
        this.NewPassword !== null &&
        this.repeatPassword !== null
      );
    },
    showSkeleton() {
      return this.activeProfileInfo === null;
    },
  },
  activated() {
    this.client =
      jsUtils.getCurClient() || JSON.parse(jsUtils.readCookie("HS-STUDIO"));
    this.user =
      JSON.parse(localStorage.getItem("studioUser")) ||
      JSON.parse(jsUtils.readCookie("HS-STUDIO"));
    this.getUserData();
  },
};
</script>
