<template>
  <div class="management-system body-bcg">
    <a-breadcrumb class="breadcrum">
      <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
        <a-breadcrumb-item>
          <router-link to="/">{{
            client ? client.ClientName : ""
          }}</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item
          ><router-link to="/user-management"
            >User Management</router-link
          ></a-breadcrumb-item
        >
      </template>
    </a-breadcrumb>
    <div class="management-system__content page-padding">
      <div class="page-title">
        <icon-base
          :width="32"
          :height="33"
          :viewBox1="25"
          :viewBox2="25"
          iconName="users"
          iconColor="transparent"
          iconStroke="#4318FF"
          ><icon-users
        /></icon-base>
        <div class="page-title__text">
          <h1>User Management</h1>
          <span>General User information</span>
        </div>
      </div>
      <div class="management-system__inner">
        <div class="management-system__tools flex justify-between items-end">
          <div class="flex gap-15 flex-1">
            <div class="input-group input-group--small max-w-200 w-full">
              <label>Search users</label>
              <div
                class="position-r search-field"
                :class="{ focus: searchActive }"
              >
                <icon-base
                  class="search-icon"
                  :width="20"
                  :height="20"
                  :viewBox1="64"
                  :viewBox2="64"
                  iconName="searchThin"
                  iconColor="transparent"
                  iconStroke="#000"
                  ><icon-search-thin
                /></icon-base>
                <a-input
                  :class="{ active: searchParametars != '' }"
                  v-model="searchParametars"
                  placeholder="Insert user name"
                  @focus="searchActive = true"
                  @blur="searchActive = false"
                >
                  <a-icon
                    slot="addonAfter"
                    theme="filled"
                    type="close-circle"
                    v-show="searchParametars != ''"
                    @click="searchParametars = ''"
                /></a-input>
              </div>
            </div>
            <div class="input-group input-group--small max-w-200 w-full">
              <label>Status</label>
              <a-select
                class="fullWidth"
                option-filter-prop="children"
                show-search
                @change="selectUserType"
                placeholder="Please select"
                default-value="All users"
              >
                <a-select-option
                  v-for="type in userTypes"
                  :key="type"
                  :value="type"
                >
                  {{ type }}
                </a-select-option>
              </a-select>
            </div>
            <div
              class="input-group input-group--small max-w-200 w-full"
              v-if="masterAccount"
            >
              <label>Account type</label>
              <a-select
                style="width: 100%"
                placeholder="Please select"
                :filterOption="false"
                @change="selectAccountType"
                :key="Math.random()"
                :default-value="activeAccountType || user.AccountId"
              >
                <a-select-option
                  v-for="(account, index) in accountTypes"
                  :key="index"
                  :value="account.AccountId"
                >
                  {{ account.AccountName }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <a-button type="primary" @click="$router.push('/create-user')"
            >Create a new user
          </a-button>
        </div>

        <div class="management-system__table box-wrapper">
          <a-table
            tableLayout="auto"
            class="custom-table"
            :columns="columns"
            :row-key="(record) => record.userID"
            :data-source="searchUserList"
            :loading="loading"
            :pagination="pagination"
            @change="handleTableChange"
            :customRow="clickTableRow"
            :row-class-name="
              (_record) =>
                _record.status === 'not active' ? 'not-active-row' : null
            "
          >
            <span slot="User" slot-scope="record">
              {{ record.firstName + " " + record.lastName }}
            </span>
            <span slot="ClientDetails" slot-scope="record">
              <a-tag v-for="(item, index) in record.ClientDetails" :key="index">
                {{ item.clientName }}
              </a-tag>
            </span>
            <span slot="action" slot-scope="record">
              <p class="row-caption">Show details</p>
              <a-popover v-model="toggleVisible[record.userID]" trigger="click">
                <template slot="content">
                  <div class="a-popover-content">
                    <button
                      class="text-btn text-btn--dark text-left"
                      type="link"
                      v-if="record.status === 'not active'"
                      @click="
                        changeUserStatus(record),
                          (toggleVisible[record.userID] = false)
                      "
                    >
                      <font-awesome-icon class="mr-5" icon="poll-h" />Change
                      status
                    </button>
                    <button
                      class="text-btn text-btn--dark text-left"
                      v-if="record.status !== 'not active'"
                      type="link"
                      @click="editUser(record)"
                    >
                      <font-awesome-icon class="mr-5" icon="edit" />Edit
                    </button>
                    <button
                      class="text-btn text-btn--dark text-left"
                      type="link"
                      v-if="
                        record.EmailId !== user.EmailId &&
                        record.status !== 'not active'
                      "
                      @click="
                        deleteUser(record),
                          (toggleVisible[record.userID] = false)
                      "
                    >
                      <font-awesome-icon
                        style="margin-right: 8px"
                        icon="trash-alt"
                      />Delete
                    </button>
                  </div>
                </template>
                <a
                  class="
                    ant-dropdown-link
                    a-popover-trigger
                    js-a-popover-trigger
                  "
                  :class="{
                    'a-popover-trigger--active': toggleVisible[record.userID],
                  }"
                  :data-sid="record.userID"
                >
                  <font-awesome-icon icon="ellipsis-v" />
                </a>
              </a-popover>
            </span>
          </a-table>
        </div>
      </div>
    </div>
    <a-modal
      title="User details"
      :visible="userDetailsModal"
      @cancel="(userDetailsModal = false), (modalChangeUserStatus = false)"
      :footer="null"
      :width="700"
    >
      <a-descriptions
        bordered
        :column="2"
        class="ant-descriptions--user-details"
      >
        <a-descriptions-item label="Name">
          {{
            userDetails !== null
              ? userDetails.firstName + " " + userDetails.lastName
              : "/"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Email">
          {{ userDetails !== null ? userDetails.EmailId : "/" }}
        </a-descriptions-item>
        <a-descriptions-item label="Country">
          {{ userDetails !== null ? userDetails.country : "/" }}
        </a-descriptions-item>
        <a-descriptions-item label="Status">
          <span class="text-capitalize">
            {{ userDetails !== null ? userDetails.status : "/" }}</span
          >
        </a-descriptions-item>
        <a-descriptions-item label="Account">
          {{ userDetails !== null ? userDetails.AccountName : "/" }}
        </a-descriptions-item>
        <a-descriptions-item label="Job">
          {{ userDetails !== null ? userDetails.jobTitle : "/" }}
        </a-descriptions-item>
        <a-descriptions-item label="Company">
          {{ userDetails !== null ? userDetails.CompanyName : "/" }}
        </a-descriptions-item>
        <a-descriptions-item label="Plan">
          {{ userDetails !== null ? userDetails.PlanName : "/" }}
        </a-descriptions-item>
        <a-descriptions-item label="Type">
          {{ userDetails !== null ? userDetails.userType : "/" }}
        </a-descriptions-item>
        <a-descriptions-item label="Clients">
          <div v-if="userDetails !== null">
            <a-tag
              v-for="(item, index) in userDetails.ClientDetails"
              :key="index"
            >
              {{ item.clientName }}
            </a-tag>
          </div>
        </a-descriptions-item>
      </a-descriptions>
      <div
        class="user-details-tools"
        v-if="userDetails !== null && userDetails.status !== 'not active'"
      >
        <a-button
          type="primary"
          @click="editUser(userDetails), (userDetailsModal = false)"
        >
          <font-awesome-icon icon="edit" /> Edit user</a-button
        >
      </div>
      <div
        class="user-details-tools"
        v-if="userDetails !== null && userDetails.status === 'not active'"
      >
        <p>
          <span>
            The user has a "not active" status. If you want to edit its
            information, you need to change the status to active.
            <a
              v-if="!modalChangeUserStatus"
              type="link"
              @click="modalChangeUserStatus = true"
            >
              Change status</a
            >
            <span class="user-details-tools__status-confirm" v-else
              >Are you sure?<a
                type="link"
                @click="modalChangeUserStatus = false"
                >No</a
              >
              <a
                type="link"
                @click="
                  setUserStatus(userDetails.userID, 1),
                    (modalChangeUserStatus = false)
                "
                >Yes</a
              ></span
            >
          </span>
        </p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import { mutations } from "@/utils/store.js";
import IconBase from "@/components/general/IconBase.vue";
import IconUsers from "@/components/icons/IconUsers.vue";
import IconSearchThin from "@/components/icons/IconSearchThin.vue";
import _ from "lodash";

const columns = [
  {
    title: "User",
    scopedSlots: { customRender: "User" },
  },
  {
    title: "Email",
    dataIndex: "EmailId",
  },
  {
    title: "User Type",
    dataIndex: "userType",
  },
  {
    title: "Clients",
    scopedSlots: { customRender: "ClientDetails" },
  },
  {
    title: "Action",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "UserManagement",
  components: {
    IconBase,
    IconUsers,
    IconSearchThin,
  },
  data() {
    return {
      columns,
      searchParametars: "",
      client: null,
      user: null,
      userList: [],
      loading: false,
      pagination: {},
      toggleVisible: [],
      accountTypes: [],
      userTypes: ["All users", "Active users", "Not active users"],
      filteredUserList: [],
      userDetailsModal: false,
      userDetails: null,
      activeUserStatusFilter: "All users",
      modalChangeUserStatus: false,
      activeAccountType: null,
      searchActive: false,
    };
  },
  watch: {
    searchUserList(newVal) {
      this.pagination.total = newVal.length;
    },
  },
  methods: {
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },
    clickTableRow(record) {
      return {
        on: {
          click: (event) => {
            this.showUserDetails(record, event);
          },
        },
      };
    },
    deleteUser(record) {
      let _this = this;
      this.$confirm({
        title: "Delete a user",
        content: () => (
          <div>
            Are you sure you want to delete {record.firstName} {record.lastName}
            ?
          </div>
        ),
        onOk() {
          _this.setUserStatus(record.userID, 0);
        },
        okText: "Yes",
      });
    },
    editUser(record, event) {
      if (
        event === undefined ||
        !event.target.closest(".js-a-popover-trigger")
      ) {
        mutations.setUserData(record);
        this.$router.push("edit-user");
      }
    },
    getUsersList(accountId) {
      this.loading = true;
      const accountUsers = new Promise((resolve) => {
        wsUtils.GetAccountUsers(
          { userid: this.user.EncrypteduserID, accountID: accountId },
          resolve
        );
      });
      accountUsers.then((data) => {
        this.searchParametars = "";
        this.userList = data;
        this.loading = false;
        const pagination = { ...this.pagination };
        pagination.total = this.userList.length;
        pagination.hideOnSinglePage = true;
        this.pagination = pagination;
        this.filterList();
      });
    },
    selectUserType(value) {
      this.activeUserStatusFilter = value;
      this.filterList();
    },
    selectAccountType(value) {
      this.activeAccountType = value;
      this.getUsersList(value);
    },
    filterList() {
      let status;
      if (this.activeUserStatusFilter === "Active users") {
        status = ["all access", "read-only"];
      } else if (this.activeUserStatusFilter === "Not active users") {
        status = ["not active"];
      } else {
        status = "all";
      }

      this.filteredUserList = _.filter(this.userList, (o) => {
        if (status === "all") {
          return o.accountID === this.activeAccountType;
        } else {
          return (
            status.includes(o.status) && o.accountID === this.activeAccountType
          );
        }
      });
      this.pagination.current = 1;
    },
    showUserDetails(data, event) {
      if (
        event === undefined ||
        !event.target.closest(".js-a-popover-trigger")
      ) {
        this.userDetails = data;
        this.userDetailsModal = true;
      }
    },
    changeUserStatus(record) {
      let _this = this;
      this.$confirm({
        title: "Change user status",
        content: () => (
          <div>
            The user{" "}
            <strong>{record.firstName + " " + record.lastName} </strong>
            has a "not active" status. Do you want to change to active?
          </div>
        ),
        onOk() {
          _this.setUserStatus(record.userID, 1);
        },
        okText: "Yes",
        onCancel() {},
      });
    },
    setUserStatus(userId, statusID) {
      const updateUserStatus = new Promise((resolve) => {
        wsUtils.UpdateUserStatus(
          { userID: userId, statusID: statusID },
          resolve
        );
      });
      updateUserStatus.then(() => {
        if (this.userDetails.userID === userId && statusID === 1) {
          this.userDetails.status = "read-only";
        }
        this.getUsersList();
      });
    },
    getAccountTypes() {
      const GetAccount = new Promise((resolve) => {
        wsUtils.GetAccount(this.user.EncrypteduserID, resolve);
      });
      GetAccount.then((data) => {
        this.accountTypes = data;
      });
    },
  },
  computed: {
    sortUserList() {
      let list = this.filteredUserList;
      const sorted = list.sort((a, b) => {
        const itemsOrder = ["all access", "read-only", "not active"];

        const aItemsIndex = itemsOrder.indexOf(a.status);
        const bItemsIndex = itemsOrder.indexOf(b.status);

        if (aItemsIndex === bItemsIndex) return a.card - b.card;

        return aItemsIndex - bItemsIndex;
      });

      return sorted;
    },
    searchUserList() {
      return this.sortUserList.filter((item) => {
        return this.searchParametars
          .toLowerCase()
          .split(" ")
          .every(
            (v) =>
              item.firstName.toLowerCase().includes(v) ||
              item.lastName.toLowerCase().includes(v)
          );
      });
    },
    masterAccount() {
      return this.user && this.user.planID === 4 ? true : false;
    },
  },
  mounted() {},
  created() {
    this.client =
      jsUtils.getCurClient() || JSON.parse(jsUtils.readCookie("HS-STUDIO"));
    this.user = jsUtils.getUserInfo();
  },
  activated() {
    this.getUsersList();
    this.getAccountTypes();
    this.activeAccountType = this.user.AccountId;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-table {
  tbody {
    td {
      &:nth-last-child(3) {
        width: 200px;
      }
    }
  }
}
.a-popover-content {
  display: flex;
  flex-direction: column;
  margin: 0 -2px;
  .ant-btn {
    color: var(--hsgreyDark);
    height: 22px;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    display: flex;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      color: var(--hsblueDark);
    }
    .ant-btn-icon-wrapper {
      min-width: 18px;
      display: block;
    }
  }
}
::v-deep .ant-descriptions {
  &--user-details {
    .ant-descriptions-item-label {
      padding: 9px 12px;
      max-width: 270px;
      font-size: 12px;
      font-weight: 600;
      background: #f0f0f0;
      border-color: #b9b9b9;
      border-right: 0;
    }
    .ant-descriptions-item-content {
      width: 150px;
      padding: 9px 12px;
      font-size: 12px;
      border-color: #b9b9b9;
      border-right: 0;
      .ant-tag {
        margin: 1px;
      }
    }
  }
}

.user-details-tools {
  margin-top: 12px;
  .ant-btn {
    font-weight: 500;
    svg {
      margin-right: 4px;
    }
  }
  p {
    padding: 10px;
    margin: 0;
    font-size: 13px;
    a {
      text-decoration: underline;
    }
  }
  &__status-confirm {
    a {
      margin-left: 7px;
    }
  }
}
</style>
