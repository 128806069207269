<template>
  <div>
    <section class="stepAuthor">
      <div class="cover">
        <div class="placeHolder" v-show="videoObj.coverImage === ''">
          <img
            src="https://cdn.dribbble.com/users/1540244/screenshots/5347913/media/36a6dcba50af9d55d204c9eb241b18ab.gif"
          />
          <p v-html="langObj['s2-upload'].waiting"></p>
        </div>
        <div class="preview" v-show="videoObj.coverImage !== ''">
          <img :src="videoObj.coverImage" />

          <a-tooltip placement="top" overlayClassName="ant-tooltip--small">
            <template
              slot="title"
              v-if="
                videoObj.keyFrames === undefined || videoObj.keyFrames === []
              "
            >
              <span v-html="langObj['s2-upload'].chooseStoryboardInfo"> </span>
            </template>
            <p
              @click="
                videoObj.keyFrames === undefined || videoObj.keyFrames === []
                  ? (isModalCoverOpen = false)
                  : (isModalCoverOpen = true)
              "
              :class="{
                disabled:
                  videoObj.keyFrames === undefined || videoObj.keyFrames === [],
              }"
              v-if="$route.meta.surveyTypeID !== 102"
              v-html="langObj['s2-upload'].changeVideoThumbnail"
            ></p>
          </a-tooltip>
        </div>
      </div>
      <div class="configus">
        <div class="questions">
          <!-- name -->
          <div
            class="quiz input-group input-group--small"
            v-if="$route.meta.surveyTypeID !== 102"
          >
            <label>
              <span v-html="langObj['s2-name'].quiz"></span>
              <a-tooltip placement="topRight">
                <template slot="title">
                  <span v-html="langObj['s2-name'].tip"></span>
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </label>
            <div>
              <a-input
                :value="videoObj.vidName"
                @blur="(event) => setVideoName(event.target.value)"
                :placeholder="langObj['s2-name'].videoNamePlaceholder"
              />
              <span v-if="videoNameValidation" class="validation"
                >The name should not contain the following characters #, &, =,
                ?</span
              >
            </div>
          </div>
          <div
            class="quiz input-group input-group--small"
            v-if="$route.meta.surveyTypeID === 102"
          >
            <label>
              <span>Cell type</span>
              <a-tooltip placement="topRight">
                <template slot="title">
                  <span
                    >Be sure to provide one set of details for each cell type:
                    Control, Aligned & Unaligned</span
                  >
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </label>
            <div class="quizContent">
              <a-select
                class="fullWidth"
                placeholder="Lorem ipsum"
                v-model="videoObj.CellType"
                @change="autoSaveStim"
              >
                <a-select-option value="Control"> Control </a-select-option>
                <a-select-option value="Aligned"> Aligned </a-select-option>
                <a-select-option value="Unaligned"> Unaligned </a-select-option>
              </a-select>
            </div>
          </div>
          <!-- brand -->
          <div
            class="quiz input-group input-group--small"
            v-if="$route.meta.surveyTypeID !== 103"
          >
            <label>
              <span>What is your brand?</span>
              <a-tooltip placement="topRight">
                <template slot="title">
                  <span
                    >Please specify the brand or sub-brand the communication is
                    related to. E.g. Coke Zero</span
                  >
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </label>
            <div>
              <a-input
                :value="videoObj.brand"
                @blur="(event) => setBrandName(event.target.value)"
                placeholder="eg:AAA"
              />
            </div>
          </div>

          <div
            class="quiz input-group input-group--small"
            v-if="
              $route.meta.brandLogoUpload && $route.meta.surveyTypeID !== 103
            "
          >
            <label>
              <span>Upload brand logo</span>
              <a-tooltip placement="topRight">
                <template slot="title">
                  <span
                    >Please upload a png or jpg logo for the brand as it would
                    be seen on tiktok. 100px square is typical.</span
                  >
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </label>
            <div>
              <a-upload-dragger
                name="file"
                accept="image/jpeg, image/png"
                :multiple="false"
                :showUploadList="false"
                :customRequest="brandLogoUpload"
                ref="upload"
              >
                <div class="flex items-center justify-center">
                  <strong v-if="!brandLogoUploading"
                    ><span
                      v-if="
                        videoObj.brandLogoUrl && videoObj.brandLogoUrl !== ''
                      "
                      >Change</span
                    ><span v-else>Select</span> a logo</strong
                  >
                  <div v-else class="flex items-center justify-center">
                    <i class="circle-loader mr-5"></i>
                    <strong>Uploading...</strong>
                  </div>
                </div>
              </a-upload-dragger>
            </div>
          </div>

          <!-- stage -->
          <div class="quiz input-group input-group--small">
            <label>
              <span v-html="langObj['s2-stage'].quiz"></span>
              <a-tooltip placement="topRight">
                <template slot="title">
                  <span v-html="langObj['s2-stage'].tip"></span>
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </label>
            <div>
              <a-select
                class="fullWidth"
                :placeholder="langObj['s2-name'].stagePlaceholder"
                v-model="videoObj.creativeStage"
                @change="updateStage"
              >
                <a-select-option value="concept"> Concept </a-select-option>
                <a-select-option value="final"> Final Cut </a-select-option>
                <a-select-option value="inMarket"> In market </a-select-option>
              </a-select>
            </div>
          </div>
          <div
            class="quiz input-group input-group--small"
            v-if="$route.meta.surveyTypeID === 102"
          >
            <label>
              <span>Video URL</span>
              <a-tooltip placement="topRight">
                <template slot="title">
                  <span
                    >Please specify the URL of the ad on YouTube or MP4
                    file.</span
                  >
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </label>
            <div class="quizContent relative">
              <a-input
                v-model="videoObj.link"
                :class="{ 'validation-border': !isUrlValid }"
              />
              <span v-if="!isUrlValid" class="validation"
                >The provided video URL is not valid</span
              >
            </div>
          </div>
          <div
            class="quiz mb-25 input-group input-group--small"
            v-if="$route.meta.surveyTypeID === 102"
          >
            <label>
              <span>Paste up to 20 content URLs</span>
              <a-tooltip placement="topRight">
                <template slot="title">
                  <span
                    >Please paste one content/context video per line up to a
                    maximum of 20 videos. These videos will be randomly selected
                    as the context for the ad, when presented to
                    respondents.</span
                  >
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </label>
            <div class="quizContent relative input-group input-group--small">
              <a-textarea
                v-model="videoObj.MainVideoURL"
                @blur="countUrls()"
                :auto-size="{ minRows: 4 }"
                :class="{ 'validation-border': isNumUrlsValid }"
              />
              <span v-if="isNumUrlsValid" class="validation">
                You have entered more than 20 content URLs</span
              >
            </div>
          </div>

          <!-- storyboard -->
          <div
            class="quiz"
            v-show="
              (videoObj.keyFrames &&
                videoObj.keyFrames.length > 0 &&
                !editMode) ||
              (videoObj.storyboard &&
                videoObj.storyboard.length > 0 &&
                !editMode)
            "
            v-if="$route.meta.surveyTypeID !== 102"
          >
            <div class="quizContent">
              <div class="desWithBtn">
                <div class="input-group">
                  <label>
                    <span v-html="langObj['s2-storyboard'].quiz"></span>
                    <a-tooltip placement="topRight">
                      <template slot="title">
                        <span v-html="langObj['s2-storyboard'].tip"></span>
                      </template>
                      <a-icon type="info-circle" />
                    </a-tooltip>
                  </label>
                  <p class="note" v-html="langObj['s2-storyboard'].note"></p>
                </div>
                <a-button
                  type="primary"
                  @click="() => (isModalOpen = true)"
                  v-html="langObj['s2-storyboard'].configure"
                ></a-button>
              </div>
            </div>
          </div>

          <div v-if="editMode" class="edit-storyboard">
            <button
              @click="storyboardImagePreviewModal = true"
              class="mr-10"
              v-html="langObj['s2-storyboard'].viewStoryboard"
            ></button>
            <button
              @click="createStoryboard"
              v-html="langObj['s2-storyboard'].regenerateStoryboard"
            ></button>
          </div>
        </div>
      </div>
      <!-- model for select storyboards -->
      <a-modal
        v-model="isModalOpen"
        width="80%"
        centered
        :title="
          !isPreviewed
            ? `${
                videoObj.mode !== 'portrait'
                  ? langObj['s2-storyboard'].selectStoryboard1
                  : langObj['s2-storyboard'].selectStoryboard1a
              } (${thumbSelected} currently selected)`
            : langObj['s2-storyboard'].storyboardLook
        "
        wrapClassName="ant-modal--storyboard-setup"
      >
        <div class="images-skeleton" v-if="videoObj.keyFrames === undefined">
          <div class="images-skeleton__loader">
            <div class="flex items-center justify-center">
              <i class="circle-loader"></i>
              <span
                v-html="langObj['s2-storyboard'].regenerateStoryboardInfo"
              ></span>
            </div>
          </div>
          <div
            class="images-skeleton__item skeleton-item"
            v-for="(item, index) in 20"
            :key="index"
          ></div>
        </div>
        <div class="images" v-show="!isPreviewed">
          <div
            v-for="(screen, index) in videoObj.keyFrames"
            class="screenThumb"
            :key="index"
            :class="{ selected: screen.selected }"
            @click="selectScreen(screen)"
          >
            <img :src="screen.img" />

            <div class="selectedIcon" v-show="screen.selected">
              <font-awesome-icon icon="check-circle" />
            </div>
          </div>
        </div>
        <div class="previewBoard" v-show="isPreviewed" :class="videoObj.mode">
          <div v-for="(screen, index) in videoObj.storyboard" :key="index">
            <img :src="screen.img" />
          </div>
        </div>
        <template slot="footer">
          <div class="butSections">
            <a-button
              @click="cancelBoard"
              v-html="langObj['s2-storyboard'].cancelBtn"
            ></a-button>
            <a-button
              type="primary"
              :disabled="enablePreviewFramesBtn"
              v-show="!isPreviewed && videoObj.keyFrames !== undefined"
              @click="showPreview"
              v-html="langObj['s2-storyboard'].previewBtn"
            >
            </a-button>
            <a-button
              type="primary"
              v-show="isPreviewed && videoObj.keyFrames !== undefined"
              @click="prepareStoryboard"
              v-html="langObj['s2-storyboard'].submitBtn"
            >
            </a-button>
          </div>
        </template>
      </a-modal>

      <!-- model for select cover image -->
      <a-modal
        v-model="isModalCoverOpen"
        width="80%"
        centered
        :okText="langObj['s2-thumb'].submitBtn"
        :title="langObj['s2-thumb'].quiz"
        @ok="isModalCoverOpen = false"
        wrapClassName="ant-modal--storyboard-setup"
      >
        <div class="images">
          <div
            v-for="(screen, index) in videoObj.keyFrames"
            class="screenThumb"
            :key="index"
            :class="{ selected: screen.img === videoObj.coverImage }"
            @click="videoObj.coverImage = screen.img"
          >
            <img :src="screen.img" />

            <div
              class="selectedIcon"
              v-show="screen.img === videoObj.coverImage"
            >
              <font-awesome-icon icon="check-circle" />
            </div>
          </div>
        </div>
      </a-modal>

      <!-- model for story board image preview -->
      <a-modal
        v-model="storyboardImagePreviewModal"
        width="80%"
        centered
        :okText="langObj['s2-storyboard'].storyboardPreviewSubmitBtn"
        :title="langObj['s2-storyboard'].storyboardPreviewTitle"
        @cancel="storyboardImagePreviewModal = false"
        :footer="null"
      >
        <div
          class="images-skeleton images-skeleton--3-column"
          v-if="storyboardImgLoader"
        >
          <div
            class="images-skeleton__item skeleton-item"
            v-for="(item, index) in 12"
            :key="index"
          ></div>
        </div>
        <img
          :src="videoObj.StoryboardURL"
          class="w-full"
          @load="storyboardImgLoader = false"
        />
      </a-modal>
    </section>
    <div class="stimBtns" v-show="videoObj.coverImage !== ''">
      <a-button @click="deleteStim"
        ><a-icon type="delete" /><span
          v-html="langObj['s2-upload'].deleteBtn"
        ></span
      ></a-button>
      <a-button
        v-if="$route.meta.surveyTypeID === 102"
        @click="reticleValidation"
        type="primary"
        class="stimBtn"
        :disabled="isDataInvalid"
        ><a-icon type="save" /><span
          v-html="langObj['s2-upload'].saveBtn"
        ></span
      ></a-button>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import axios from "axios";

const key = "updatable";
export default {
  name: "CreativeConfig",
  props: {
    videoObj: {
      type: Object,
      default() {
        return {};
      },
    },
    langObj: {
      type: Object,
      default() {
        return {};
      },
    },
    surveyID: String,
    stimID: [String, Number],
    userId: String,
  },
  data() {
    return {
      videoName: "",
      creativeStage: "",
      storyboardCompleted: false,
      isModalOpen: false,
      isModalCoverOpen: false,
      thumbNeed: 0,
      thumbSelected: 0,
      isPreviewed: false,
      storyboardImagePreviewModal: false,
      editMode: false,
      storyboardImgLoader: true,
      brandLogoURL: null,
      brandLogoUploading: false,
      videoNameValidation: false,
      numUrls: 0,
      isUrlValid: true,
      isNumUrlsValid: false,
    };
  },
  computed: {
    // autoSaveProperties() {
    //   return (
    //     this.storyboardCompleted &&
    //     this.videoObj.vidName !== "" &&
    //     this.videoObj.creativeStage !== "" &&
    //     this.videoObj.coverImage !== ""
    //   );
    // },
    enablePreviewFramesBtn() {
      if (this.videoObj.mode !== "portrait") {
        return !(
          this.thumbSelected === 6 ||
          this.thumbSelected === 9 ||
          this.thumbSelected === 12 ||
          this.thumbSelected === 15
        );
      } else {
        return !(this.thumbSelected === 8);
      }
    },
    isDataInvalid() {
      const videoObj = this.videoObj;
      const requiredFields = [
        "CellType",
        "brand",
        "creativeStage",
        "link",
        "MainVideoURL",
      ];
      for (const field of requiredFields) {
        if (!videoObj[field]) {
          return true;
        }
      }

      return false;
    },
  },
  watch: {
    "videoObj.link": function (newVal) {
      if (newVal !== "" && this.videoObj.stimID === 0) {
        // NEWLY ADDED VIDEO

        if (this.videoObj.isYoutube) {
          // we need to get title and duration information for youtube video
          this.getYTVidInfo(newVal);
        }
        // no matter from YT or local, as long as link is ready, we will get keyframes.
        this.parseVideo();
      }
    },
    "videoObj.storyboard": function () {
      // if (newVal) {
      //   if (newVal.length === 0) {
      //     this.storyboardCompleted = false;
      //   } else {
      //     this.storyboardCompleted = true;
      //   }
      // } else {
      //   this.storyboardCompleted = false;
      // }

      this.$emit("onConfigUpdate", {
        key: "storyboard",
        completed: this.storyboardCompleted,
      });
    },
    "videoObj.vidName": function (val) {
      if (val !== "") {
        this.$emit("onConfigUpdate", { key: "name", completed: true });
      } else {
        this.$emit("onConfigUpdate", { key: "name", completed: false });
      }
    },
    "videoObj.coverImage": function (val) {
      this.storyboardCompleted = false;
      if (val !== "") {
        this.$emit("onConfigUpdate", { key: "thumb", completed: true });
      } else {
        this.$emit("onConfigUpdate", { key: "thumb", completed: false });
      }
    },
    "videoObj.creativeStage": function (val) {
      if (val !== "") {
        this.$emit("onConfigUpdate", { key: "stage", completed: true });
      } else {
        this.$emit("onConfigUpdate", { key: "stage", completed: false });
      }
    },
    "videoObj.stimID": function () {
      this.setEditMode();
      this.thumbNeed = this.videoObj.mode === "landscape" ? 9 : 8;
      if (this.videoObj.keyFrames) {
        this.thumbSelected = this.videoObj.keyFrames.filter((screen) => {
          return screen.selected;
        }).length;
      } else {
        this.thumbSelected = 0;
      }
    },
    "videoObj.brandLogoUrl": function (val) {
      if (val !== "") {
        this.$emit("onConfigUpdate", { key: "brandLogo", completed: true });
      } else {
        this.$emit("onConfigUpdate", { key: "brandLogo", completed: false });
      }
    },
    numUrls: function (val) {
      if (val > 0 && val <= 20) {
        this.$emit("onConfigUpdate", { key: "mainVideoURL", completed: true });
      } else {
        this.$emit("onConfigUpdate", { key: "mainVideoURL", completed: false });
      }
    },
    "videoObj.MainVideoURL": function () {
      this.countUrls();
    },
    "videoObj.CellType": function (val) {
      if (val !== "") {
        this.$emit("onConfigUpdate", { key: "cellType", completed: true });
      } else {
        this.$emit("onConfigUpdate", { key: "cellType", completed: false });
      }
    },
  },
  methods: {
    reticleValidation() {
      const urlPattern =
        /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(:\d+)?(\/\S*)?$/;
      this.isUrlValid = urlPattern.test(this.videoObj.link);
      this.isNumUrlsValid = this.numUrls > 20;

      if (this.isUrlValid && !this.isNumUrlsValid) {
        this.videoObj.stimID !== 0 ? this.saveStim("edit") : this.saveStim();
      }
    },
    brandLogoUpload(data) {
      let form = new FormData();
      form.append("file", data.file);
      this.brandLogoUploading = true;

      const uploadBrandLogo = new Promise((resolve) => {
        wsUtils.UploadBrandLogo(
          {
            surveyId: this.$route.params.id,
            form,
          },
          resolve
        );
      });

      uploadBrandLogo.then((data) => {
        this.brandLogoUploading = false;
        if (data.success) {
          this.$notification["success"]({
            message: "Upload brand logo",
            description: "Logo is successfully uploaded.",
            placement: "bottomLeft",
            duration: 5,
          });
          this.videoObj.brandLogoUrl = data.imageURL;
          this.autoSaveStim();
        } else {
          this.$notification["error"]({
            message: "Upload brand logo",
            description:
              "Something was wrong, please try again or contact our support.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
      });
    },
    setVideoName(data) {
      const regex = /[#&=?]/g;
      if (data !== this.videoObj.vidName && data !== "") {
        this.videoObj.vidName = data;
        this.autoSaveStim();
      }
      if (regex.test(data)) {
        this.setVideoNameError();
      } else {
        this.videoNameValidation = false;
      }
    },
    setBrandName(data) {
      if (data !== this.videoObj.brand && data !== "") {
        this.videoObj.brand = data;
        this.autoSaveStim();
      }
    },
    saveStim(editMode) {
      if (this.stimID !== 0) {
        // when upload from local, we will have to create a placeholder video
        // hence, we need to update on that placeholder.
        this.videoObj.stimID = this.stimID;
      }
      let stimObj = {
        StimID: this.videoObj.stimID,
        AssetID: this.videoObj.assetID,
        VideoName: this.videoObj.vidName,
        VideoURL: this.videoObj.generatedLink
          ? this.videoObj.generatedLink
          : this.videoObj.link,
        VideoMode: this.videoObj.mode,
        CreativeStage: this.videoObj.creativeStage,
        CoverImage: this.videoObj.coverImage,
        SurveyId: this.surveyID,
        StoryBoard: this.videoObj.storyboard || [],
        VideoPlayLimit: 10,
        AdDuration: this.videoObj.AdDuration,
        BRAND: this.videoObj.brand,
        userId: this.userId,
      };
      let mainVideoURL = "";
      if (this.videoObj.MainVideoURL) {
        mainVideoURL = this.videoObj.MainVideoURL.replace(/[\n\r]+/g, ",");
      }
      let reticleStimObj = {
        StimID: this.videoObj.stimID,
        CellName: this.videoObj.CellType,
        ad1preroll: this.videoObj.generatedLink
          ? this.videoObj.generatedLink
          : this.videoObj.link,
        CreativeStage: this.videoObj.creativeStage,
        ImageURL: this.videoObj.coverImage,
        AdDuration: this.videoObj.AdDuration,
        MainVideoURL: mainVideoURL,
        CellType: this.videoObj.CellType,
        Brand: this.videoObj.brand,
        SurveyId: this.surveyID,
        userId: this.userId,
      };

      if (
        this.$route.meta.brandLogoUpload ||
        this.$route.meta.surveyTypeID === 103
      ) {
        stimObj.BrandImageURL = this.videoObj.brandLogoUrl;
      }

      if (
        stimObj.AdDuration === 0 ||
        stimObj.AdDuration === null ||
        isNaN(stimObj.AdDuration)
      ) {
        this.$notification["error"]({
          message: "Save video status!",
          description:
            "Something was wrong, please refresh the page and try again.",
          placement: "bottomLeft",
          duration: 5,
        });
        return;
      }

      this.$message.loading({ content: "Saving...", key });
      const savePromise = new Promise((resolve) => {
        wsUtils[this.$route.meta.insertSurveyStimWS || "InsertSurveyStim"](
          this.$route.meta.surveyTypeID === 102 ? reticleStimObj : stimObj,
          resolve
        );
      });

      savePromise.then((data) => {
        jsUtils.setSurveysApiTrigger(true);
        this.$message.success({ content: "Saved!", key, duration: 2 });
        this.videoObj.stimID = data.StimId;
        // this.videoObj.storyboard = new Array().push(data.Storyboard);
        this.videoObj.StoryboardURL = data.Storyboard;
        this.$emit("stimSavedEvent", editMode);
        this.$emit("newStimId", data.StimId);

        stimObj.StimID = data.StimId;
        reticleStimObj.StimID = data.StimId;

        let currentEditableSurveyData = jsUtils.getEditableSurvey();
        if (currentEditableSurveyData) {
          let index = currentEditableSurveyData.StimDetails.findIndex(
            (x) => x.StimID == data.StimId
          );

          if (index === -1) {
            currentEditableSurveyData.StimDetails.push(
              this.$route.meta.surveyTypeID === 102 ? reticleStimObj : stimObj
            );
          } else {
            currentEditableSurveyData.StimDetails[
              currentEditableSurveyData.StimDetails.findIndex(
                (el) => el.StimID === data.StimId
              )
            ] =
              this.$route.meta.surveyTypeID === 102 ? reticleStimObj : stimObj;
          }

          let editableSurvey = {
            StimDetails: [...currentEditableSurveyData.StimDetails],
          };
          jsUtils.setEditableSurvey({
            ...currentEditableSurveyData,
            ...editableSurvey,
          });
        }
      });
    },
    deleteStim() {
      if (this.videoObj.stimID === 0) {
        this.$emit("stimDeletedEvent");
      } else {
        const deletePromise = new Promise((resolve) => {
          wsUtils.DeleteSurveyStim(this.videoObj.stimID, resolve);
        });
        deletePromise.then((data) => {
          this.$emit("stimDeletedEvent");

          let currentEditableSurveyData = jsUtils.getEditableSurvey();
          if (currentEditableSurveyData) {
            let index = currentEditableSurveyData.StimDetails.findIndex(
              (x) => x.StimID == this.videoObj.stimID
            );

            if (index !== -1 && data.Success === true) {
              currentEditableSurveyData.StimDetails.splice(index, 1);

              let editableSurvey = {
                StimDetails: [...currentEditableSurveyData.StimDetails],
              };
              jsUtils.setEditableSurvey({
                ...currentEditableSurveyData,
                ...editableSurvey,
              });
            }
          }
        });
      }
    },
    updateStage(value) {
      this.creativeStage = value;
      this.autoSaveStim();
    },
    setVideoDimensionMode() {
      // landscape and portratit
      const self = this;
      let img = new Image();
      img.onload = function () {
        self.videoObj.mode =
          this.width > this.height ? "landscape" : "portrait";
        self.thumbNeed = self.videoObj.mode === "landscape" ? 9 : 8;
      };
      img.src = this.videoObj.coverImage + "?" + Math.random();
    },
    selectScreen(screen) {
      screen.selected = !screen.selected;
      this.thumbSelected = this.videoObj.keyFrames.filter((screen) => {
        return screen.selected;
      }).length;
    },
    cancelBoard() {
      if (this.isPreviewed) {
        // in preview mode;
        this.isPreviewed = false;
      } else {
        this.isModalOpen = false;
      }
    },
    showPreview() {
      this.videoObj.storyboard = this.videoObj.keyFrames.filter((frame) => {
        return frame.selected;
      });
      this.isPreviewed = true;
    },
    prepareStoryboard() {
      // ws for saving storyboard
      this.isPreviewed = false;
      this.isModalOpen = false;
      this.videoObj.storyboard = this.videoObj.keyFrames.filter((frame) => {
        return frame.selected;
      });
      this.videoObj.storyboard = this.videoObj.storyboard.map((frame) => {
        return frame.img;
      });
      this.storyboardCompleted = true;
      this.autoSaveStim();
    },
    getYTVidInfo(link) {
      // axios
      //   .get(`https://noembed.com/embed?url=${link}`, {})
      //   .then((response) => {
      //     this.videoObj.vidName = response.data.title;
      //   });
      const getYTID = (url) => {
        var ID = "";
        url = url
          .replace(/(>|<)/gi, "")
          .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        if (url[2] !== undefined) {
          ID = url[2].split(/[^0-9a-z_-]/i);
          ID = ID[0];
        } else {
          ID = url;
        }
        return ID.toString();
      };

      const youTubeID = getYTID(link);

      axios
        .get(
          `https://ytapi.gitnol.com/get.php?id=${youTubeID}&apikey=ToMabyrg0817`,
          {}
        )
        .then((response) => {
          this.videoObj.vidName = response.data.title;
          this.videoObj.AdDuration = Number(response.data.lengthsec);
          if (this.videoObj.AdDuration > 480) {
            this.$emit("stopUpload", this.videoObj.assetID);
          }
        });
    },
    parseVideo() {
      const keyFramePromise = new Promise((resolve) => {
        wsUtils.GenerateFramesFromVideo(this.videoObj.link, resolve);
      });
      keyFramePromise.then((data) => {
        this.videoObj.generatedLink = data.VideoLink;
        this.videoObj.keyFrames = data.Screens.map((screen) => {
          return { img: screen.Screens, selected: false };
        });
        this.thumbSelected = 0;
        this.videoObj.coverImage = data.Screens[0].Screens;
        // landscape and portratit
        this.setVideoDimensionMode();
        this.autoSaveStim();
      });
    },
    createStoryboard() {
      this.isModalOpen = true;
      if (this.videoObj.keyFrames === undefined) {
        this.parseVideo();
      }
    },
    setEditMode() {
      this.countUrls();
      if (this.videoObj.StoryboardURL !== undefined) {
        this.editMode = true;
      } else {
        this.editMode = false;
      }
    },
    autoSaveStim() {
      if (this.$route.meta.surveyTypeID === 102) {
        return;
      }

      this.setUABrandLogo();

      const regex = /[#&=?]/g;
      if (this.videoObj.stimID !== 0) {
        if (
          this.videoObj.vidName !== "" &&
          this.videoObj.creativeStage !== "" &&
          this.videoObj.coverImage !== "" &&
          ((this.$route.meta.brandLogoUpload &&
            this.videoObj.brandLogoUrl !== "") ||
            !this.$route.meta.brandLogoUpload)
        ) {
          if (
            regex.test(this.videoObj.vidName) &&
            this.$route.meta.surveyTypeID !== 102
          ) {
            this.setVideoNameError();
          } else {
            this.videoNameValidation = false;
            this.saveStim("edit");
          }
        }
      } else {
        if (
          (this.storyboardCompleted || this.$route.meta.surveyTypeID === 102) &&
          this.videoObj.vidName !== "" &&
          this.videoObj.creativeStage !== "" &&
          this.videoObj.coverImage !== "" &&
          ((this.$route.meta.brandLogoUpload &&
            this.videoObj.brandLogoUrl !== "") ||
            !this.$route.meta.brandLogoUpload)
        ) {
          if (
            regex.test(this.videoObj.vidName) &&
            this.$route.meta.surveyTypeID !== 102
          ) {
            this.setVideoNameError();
          } else {
            this.videoNameValidation = false;
            this.saveStim();
          }
        }
      }
    },
    setVideoNameError() {
      this.videoNameValidation = true;

      this.$notification["error"]({
        message: "The video name error!",
        description: `The Name should not contain the following characters #, &, =, ?`,
        placement: "bottomLeft",
        duration: 3,
      });
    },
    countUrls() {
      if (this.videoObj.MainVideoURL) {
        const urls = this.videoObj.MainVideoURL.split("\n");
        let count = 0;
        for (let i = 0; i < urls.length; i++) {
          if (urls[i].trim() !== "") {
            count++;
          }
        }
        this.numUrls = count;
      } else {
        this.numUrls = 0;
      }
    },
    setUABrandLogo() {
      if (this.$route.meta.surveyTypeID === 103) {
        this.videoObj.brandLogoUrl =
          "https://s3.us-east-1.amazonaws.com/simage.hotspex.com/mobileApp/adInContext/tiktokAlchemer/public/assets/ua.png";
      }
    },
  },
  created() {
    this.setEditMode();
  },
  activated() {
    this.storyboardCompleted = false;
    this.numUrls = 0;
    this.isUrlValid = true;
    this.isNumUrlsValid = false;
  },
};
</script>

<style lang="scss" scoped>
.stepAuthor {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 1em;
  margin: 0;
}

section.stepAuthor .quiz:last-of-type {
  margin-bottom: 0;
}

.stimBtns {
  margin-top: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  .ant-btn {
    margin: 0 10px;
  }
}

.cover {
  height: 320px;
  font-size: 0.9em;
  overflow: hidden;

  .placeHolder {
    height: 100%;
    text-align: center;
    position: relative;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    p {
      position: absolute;
      top: 10px;
      width: 100%;
    }
  }

  .preview {
    width: 100%;
    height: 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    p {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      background: rgba(0, 0, 0, 0.85);
      margin: 0;
      color: white;
      padding: 0.5em 0;
      cursor: pointer;

      &.disabled {
        opacity: 0.6;
        cursor: default;
      }

      &:hover:not(.disabled) {
        color: var(--hsblue);
      }
    }
  }
}

.configus {
  font-size: 0.9em;
}

.desWithBtn {
  display: flex;
  place-content: space-between;
  align-items: flex-start;

  p {
    margin: 0;
  }
}

.note {
  font-size: 0.875em;
  color: var(--lightText);
}

.edit-storyboard {
  border-top: 1px solid #ededed;
  padding-top: 18px;
  display: flex;
  align-items: center;
  gap: 15px;
  button {
    padding: 0;
    background: transparent;
    border: 0;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: var(--red);
    }
  }
}

.quiz {
  ::v-deep .ant-upload-btn {
    padding: 9px 0;
  }
}

.validation {
  font-size: 12px;
  line-height: 14px;
  color: red;
  position: absolute;
  top: 100%;
  left: 0;
}
</style>

<style lang="scss">
.ant-modal {
  &--storyboard-setup {
    backdrop-filter: blur(20px) brightness(30%);
    padding: 40px 0;
    .ant-modal-body {
      height: 80vh;
      overflow: auto;

      .images {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        grid-gap: 1em;
        height: 100%;
        overflow: auto;
      }

      .previewBoard {
        display: grid;
        overflow: auto;
        gap: 1em;

        img {
          max-width: 100%;
        }
      }

      .landscape {
        grid-template-columns: repeat(3, 1fr);
      }

      .portrait {
        grid-template-columns: repeat(4, 1fr);
      }

      .butSections {
        display: flex;
        justify-content: end;
        column-gap: 1em;
        padding-right: 2em;
      }

      .screenThumb {
        position: relative;
        cursor: pointer;
        img {
          max-width: 100%;
        }

        .selectedIcon {
          position: absolute;
          right: 0;
          bottom: 0;
          color: #bfff9f;
          font-size: 3em;
          line-height: 1;
        }
      }
      .screenThumb.selected img {
        filter: grayscale(100%);
      }
    }
    .ant-modal-title {
      strong {
        color: var(--hsblue);
      }
    }
  }
  .images-skeleton {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 1em;
    height: 100%;
    overflow: auto;
    &--3-column {
      grid-template-columns: repeat(auto-fit, minmax(31.33%, 1fr));
    }
    &__loader {
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      padding: 10px;
      border-radius: 4px;
      border: 1px solid #e7e7e7;
    }
    &__item {
      padding-bottom: 56.25%;
    }
  }
}
</style>
