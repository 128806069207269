<template>
  <section>
    <h4 v-html="langObj['s2-upload'].youtube1"></h4>
    <!-- <a-input placeholder="https://www.youtube.com/xxxxx" /> -->
    <div
      class="ybSection"
      :class="{
        error: ytLinkError,
        'btn-disabled': ytLink === '',
      }"
    >
      <div class="position-r input-group input-group--small">
        <a-input-search
          placeholder="https://www.youtube.com/xxxxx"
          enter-button="Fetch"
          @search="sendYBLink"
          v-model="ytLink"
        />
        <span class="validation-text" v-if="ytLinkError"
          >Please enter a valid YouTube video URL</span
        >
      </div>
      <div class="youtubeTip">
        <img src="/images/icons/youtubeLogo.svg" class="uploadIcon" />
        <p class="ant-upload-text" v-html="langObj['s2-upload'].youtube2"></p>
      </div>
    </div>
  </section>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "YouTubeUpload",
  data() {
    return {
      ytLink: "",
      ytLinkError: false,
    };
  },
  props: {
    langObj: {},
  },
  methods: {
    sendYBLink(value) {
      if (
        value.indexOf("youtu.be") === -1 &&
        value.indexOf("www.youtube") === -1
      ) {
        this.ytLinkError = true;
      } else {
        this.ytLinkError = false;
        let assetID = uuidv4();
        this.$emit("getYouTubeLink", { link: value, assetID: assetID });
        this.ytLink = "";
      }
    },
  },
  activated() {
    this.ytLinkError = false;
  },
};
</script>

<style lang="scss" scoped>
section {
  padding-left: 10px;
  border-radius: 8px;
  display: grid;
  grid-template-rows: auto 1fr;

  .uploadIcon {
    height: 28px;
  }

  .youtubeTip {
    display: grid;
    align-items: center;
    justify-items: center;
    align-content: center;
    grid-gap: 1em;
    opacity: 0.9;
    // background-color: #fafafa;
    // border-radius: 4px;
    // border: 1px dotted #d9d9d9;
  }

  .ybSection {
    border: 1px solid var(--lineGrey);
    padding: 20px 15px;
    border-radius: 10px;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-gap: 1em;
    background: #fbfbfb;
    &.error {
      ::v-deep .ant-input {
        border-color: red;
      }
    }
    &.btn-disabled {
      ::v-deep .ant-btn {
        pointer-events: none;
        opacity: 0.6;
      }
    }
    .validation-text {
      position: absolute;
      left: 0;
      bottom: -20px;
      font-size: 12px;
    }
    .ant-upload-text {
      font-size: 14px;
      text-align: center;
      color: var(--iconBGGrey);
    }
  }
}
</style>
