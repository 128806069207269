<template>
  <div class="define-report body-bcg">
    <a-breadcrumb
      class="breadcrum"
      v-if="user !== null && user.SkinIdentifier !== 'pg'"
    >
      <template>
        <a-breadcrumb-item>
          <a href="/">{{
            client ? client.ClientName : ""
          }}</a></a-breadcrumb-item
        >
        <a-breadcrumb-item
          ><router-link to="/define-report"
            >Define Report</router-link
          ></a-breadcrumb-item
        >
      </template>
    </a-breadcrumb>

    <div class="page-padding">
      <div class="page-title">
        <icon-base
          :width="29"
          :height="29"
          :viewBox1="192"
          :viewBox2="192"
          iconName="reports"
          iconColor="#4318FF"
          iconStroke="transparent"
          ><icon-reports
        /></icon-base>
        <div class="page-title__text">
          <h1>Define report</h1>
          <span>Report Parameters</span>
        </div>
      </div>

      <div v-if="loading" class="max-w-970">
        <Loader text="Loading data..." :paddingTop="150"></Loader>
      </div>

      <div v-else>
        <div class="box-wrapper max-w-970 mb-25">
          <div class="define-report__item">
            <h2 class="section-title">Details</h2>

            <div class="define-report__item-details">
              <label class="label">Name</label>
              <p>
                {{
                  reportRequirements.surveyName !== null &&
                  reportRequirements.surveyName !== ""
                    ? reportRequirements.surveyName
                    : "/"
                }}
              </p>
            </div>
            <div class="define-report__item-details">
              <label class="label">Learning objectives</label>
              <p>
                {{
                  reportRequirements.learning_objective !== null &&
                  reportRequirements.learning_objective !== ""
                    ? reportRequirements.learning_objective
                    : "/"
                }}
              </p>
            </div>
            <div class="define-report__item-details">
              <label class="label">Internal project</label>
              <p>
                {{
                  reportRequirements.internal_project !== null &&
                  reportRequirements.internal_project !== ""
                    ? reportRequirements.internal_project
                    : "/"
                }}
              </p>
            </div>
            <div class="define-report__item-details">
              <label class="label">Tags</label>
              <p>
                {{
                  reportRequirements.tags !== null &&
                  reportRequirements.tags !== ""
                    ? reportRequirements.tags
                    : "/"
                }}
              </p>
            </div>
            <div class="define-report__item-details">
              <label class="label">Cost center</label>
              <p>
                {{
                  reportRequirements.costCenter !== null &&
                  reportRequirements.costCenter !== ""
                    ? reportRequirements.costCenter
                    : "/"
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="box-wrapper max-w-970 mb-25">
          <div class="define-report__item">
            <h2 class="section-title">Key messages</h2>

            <div class="define-report__item-key-messages-wrapper">
              <label class="label">User key messages</label>
              <span v-if="reportRequirements.userkeymessage.length === 0"
                >/</span
              >
              <div
                v-for="(item, index) in reportRequirements.userkeymessage"
                :key="index"
                class="general-checkbox general-checkbox--disabled"
              >
                <input
                  type="checkbox"
                  :id="index"
                  value=""
                  disabled
                  checked
                /><label :for="index">{{ item.keyMessages }}</label>
              </div>
            </div>
            <div class="define-report__item-key-messages-wrapper">
              <label class="label">Category key messages</label>
              <span v-if="reportRequirements.categoryKeymessage.length === 0"
                >/</span
              >
              <div
                v-for="item in reportRequirements.categoryKeymessage"
                :key="item.id"
                class="general-checkbox"
              >
                <input
                  type="checkbox"
                  :id="item.id"
                  v-model="item.selected"
                  value=""
                  :checked="item.selected"
                /><label :for="item.id">{{ item.keyMessages }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="box-wrapper max-w-970 mb-25">
          <h2 class="section-title">Further information</h2>
          <div class="input-group">
            <label
              >Please provide any further information that will help us
              customize the report for you</label
            >
            <a-textarea
              v-model="reportRequirements.importantInfo"
              :auto-size="{ minRows: 4 }"
            />
          </div>
        </div>

        <div class="max-w-970 flex justify-end gap-15">
          <a-button @click="$router.push('/')"> Cancel </a-button>
          <a-button type="primary" @click="save" :disabled="disableSaveBtn">
            Save
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import Loader from "@/components/general/Loader.vue";
import _ from "lodash";
import IconBase from "@/components/general/IconBase.vue";
import IconReports from "@/components/icons/IconReports.vue";

export default {
  name: "DefineReport",
  components: {
    Loader,
    IconBase,
    IconReports,
  },
  props: {},
  data() {
    return {
      user: null,
      client: null,
      reportRequirements: {
        surveyName: null,
        learning_objective: null,
        internal_project: null,
        tags: null,
        costCenter: null,
        userkeymessage: [],
        categoryKeymessage: [],
        importantInfo: null,
      },
      tempReportRequirements: {},
      loading: true,
      surveyID: null,
    };
  },
  computed: {
    disableSaveBtn() {
      let objectsCompare = _.isEqual(
        this.reportRequirements,
        this.tempReportRequirements
      );
      return objectsCompare;
    },
  },
  methods: {
    surveyReportRequirements() {
      const getSurveyReportRequirements = new Promise((resolve) => {
        wsUtils.GetSurveyReportRequirements(
          {
            surveyId: this.surveyID,
          },
          resolve
        );
      });

      getSurveyReportRequirements.then((data) => {
        this.reportRequirements = data;
        this.tempReportRequirements = JSON.parse(
          JSON.stringify(this.reportRequirements)
        );
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      });
    },
    save() {
      let selectedKeyMessages = this.reportRequirements.categoryKeymessage
        .filter((item) => {
          return item.selected;
        })
        .map((item) => {
          return item.id;
        });
      let model = {
        surveyId: this.surveyID,
        userId: this.user.EncrypteduserID,
        importantInfo: this.reportRequirements.importantInfo,
        selectedKeyMessages: selectedKeyMessages,
      };
      const saveSurveyReportRequirements = new Promise((resolve) => {
        wsUtils.SaveSurveyReportRequirements(
          { SaveSurveyReportRequirements: model },
          resolve
        );
      });

      saveSurveyReportRequirements.then((data) => {
        if (data.Success) {
          this.$notification["success"]({
            message: "Well done!",
            description: "Your data are successfully save.",
            placement: "bottomLeft",
            duration: 6,
          });
          this.$router.push("/");
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Something was wrong, please try again or contact our support.",
            placement: "bottomLeft",
            duration: 6,
          });
        }
      });
    },
    resetData() {
      this.reportRequirements = {
        surveyName: null,
        learning_objective: null,
        internal_project: null,
        tags: null,
        costCenter: null,
        userkeymessage: [],
        categoryKeymessage: [],
        importantInfo: null,
      };
    },
  },

  created() {},
  activated() {
    this.resetData();
    this.loading = true;
    this.client = jsUtils.getCurClient();
    this.user = jsUtils.getUserInfo();
    this.surveyID = this.$route.params.id;
    this.surveyReportRequirements();
  },
};
</script>

<style lang="scss" scoped>
.define-report {
  position: relative;
  min-height: 100%;

  &__item {
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__item-details {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
  &__item-key-messages-wrapper {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
    .general-checkbox {
      margin-bottom: 8px;
      label {
        line-height: 16px;
        font-weight: 400;
      }
      &--disabled {
        label {
          cursor: default;
          &:after {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
</style>
