<template>
  <div class="authoring-wrapper">
    <div class="flex h-full">
      <ProgressBar
        :stepIndex="stepIndex"
        :surveyID="surveyID"
        :stepsType="surveyTypeID === 102 ? 'Reticle Alignment Test' : 'video'"
      />
      <section class="wrapper">
        <a-breadcrumb class="breadcrum breadcrum--authoring">
          <a-breadcrumb-item
            ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
          >
          <a-breadcrumb-item
            ><a href="/products">Products</a></a-breadcrumb-item
          >
          <a-breadcrumb-item>{{ $route.meta.surveyTitle }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="wrapper__inner">
          <div class="page-title">
            <icon-base
              :width="32"
              :height="33"
              :viewBox1="22"
              :viewBox2="23"
              iconName="creative"
              iconColor="#4318FF"
              ><icon-creative
            /></icon-base>
            <div class="page-title__text">
              <h1>Creative</h1>
              <span>Creative Submission Details</span>
            </div>
          </div>
          <div class="box-wrapper">
            <div class="section-info">
              <p
                class="font-size-14 mb-20"
                v-html="
                  surveyTypeID === 102
                    ? langObj['s2-upload'].quizReticle
                    : langObj['s2-upload'].quiz
                "
              ></p>
            </div>

            <div class="creatives">
              <div class="tabs">
                <!-- upload tab -->
                <div
                  class="tab upload"
                  :class="{ disabled: tabDisabled, active: showUploadPanel }"
                  @click="addNewStim"
                >
                  <div class="icon">
                    <font-awesome-icon icon="cloud-upload-alt" />
                  </div>
                  <div
                    class="iconDes"
                    v-html="langObj['s2-upload'].addCreative"
                  ></div>
                </div>
                <!-- video tabs -->
                <div
                  class="tab"
                  v-for="(creative, index) in creatives"
                  :key="index"
                  :class="{
                    active: curTabIdex === index && showUploadPanel === false,
                    processing: creative.coverImage === '',
                    disabled: tabDisabled,
                  }"
                  @click="viewStim(index)"
                >
                  <div v-show="creative.coverImage === ''">
                    <div class="icon">
                      <a-icon type="loading" />
                    </div>
                    <div
                      class="iconDes"
                      v-html="langObj['s2-upload'].processingCreative"
                    ></div>
                  </div>
                  <div
                    class="thumbHolder"
                    v-if="$route.meta.surveyTypeID !== 102"
                    v-show="creative.coverImage !== ''"
                  >
                    <img :src="creative.coverImage" />
                  </div>
                  <div
                    v-else-if="creative.coverImage !== ''"
                    class="thumbHolder flex items-center justify-center"
                  >
                    <strong>{{
                      creative.CellType !== undefined &&
                      creative.CellType !== ""
                        ? creative.CellType
                        : "Video " + (index + 1)
                    }}</strong>
                  </div>
                </div>
              </div>
              <!-- upload panel -->
              <div class="creativeUpload" v-show="showUploadPanel">
                <LocalFileUpload
                  :surveyID="surveyID"
                  :langObj="langObj"
                  @fileUpdateEvent="localFileInfoUpdate"
                />
                <span class="or"
                  ><span v-html="langObj['s2-upload'].or">OR</span></span
                >
                <YouTubeUpload
                  v-on:getYouTubeLink="processYTLink"
                  :langObj="langObj"
                />
                <div
                  class="creativeUpload__stim-limit"
                  v-if="
                    $route.meta.surveyTypeID !== 102
                      ? creatives.length >= 5
                      : creatives.length >= 3
                  "
                >
                  <p>
                    You have reached the limit of
                    {{ $route.meta.surveyTypeID !== 102 ? 5 : 3 }} videos.
                  </p>
                </div>
              </div>
              <!-- video configure panel -->
              <div class="creativeConfig" v-show="!showUploadPanel">
                <CreativeConfig
                  :videoObj="creatives[curTabIdex]"
                  :surveyID="surveyID"
                  :stimID="stimID"
                  :langObj="langObj"
                  :userId="user !== null ? user.EncrypteduserID : null"
                  @stimSavedEvent="stimSaved"
                  @stimDeletedEvent="stimDeleted"
                  @onConfigUpdate="configUpdate"
                  @stopUpload="stopUpload"
                  @newStimId="newStimId"
                />
              </div>
            </div>
          </div>
          <StepNaviBar
            :stepIndex="stepIndex"
            :nextDisable="nextDisable"
            @nextHandler="nextStep"
            :prevDisable="prevDisable"
            @prevHandler="prevStep"
            prevBtnTooltip="Back to General Settings"
            nextBtnTooltip="Go to Brand Strategy"
            :stepsType="
              surveyTypeID === 102 ? 'Reticle Alignment Test' : 'video'
            "
          />
        </div>
      </section>
    </div>
    <CompletionProgress :checkList="quizs"></CompletionProgress>
  </div>
</template>

<script>
import LocalFileUpload from "@/components/videoAds/LocalFileUpload.vue";
import YouTubeUpload from "@/components/videoAds/YouTubeUpload.vue";
import CreativeConfig from "@/components/videoAds/CreativeConfig.vue";
import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar1.vue";
import { mutations } from "@/utils/store.js";
// import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";
import CompletionProgress from "@/components/general/CompletionProgress.vue";
import IconBase from "@/components/general/IconBase.vue";
import IconCreative from "@/components/icons/IconCreative.vue";

export default {
  name: "CreativeUpload",
  components: {
    LocalFileUpload,
    YouTubeUpload,
    CreativeConfig,
    ProgressBar,
    StepNaviBar,
    CompletionProgress,
    IconBase,
    IconCreative,
  },
  mixins: [surveyPriceMixin],
  data() {
    return {
      videoObj: {},
      showUploadPanel: true,
      tabDisabled: false,
      creatives: [],
      curTabIdex: 0,
      stimID: 0,
      stepIndex: 2,
      quizs: [],
      surveyID: "0",
      langObj: {},
      client: {},
      configStatus: {
        nameReady: false,
        stageReady: false,
        storyboardReady: false,
        thumbReady: false,
        brandLogo: false,
        cellType: false,
        mainVideoURL: false,
      },
      editableSurvey: null,
      projectSummary: null,
      user: null,
      surveyTypeID: null,
    };
  },
  watch: {
    showUploadPanel(newVal) {
      if (newVal) {
        // in setting mode, we just simply remind user to upload video
        this.uploadQuizs();
      } else {
        // in config mode, we need different question
        this.configQuizs();
      }
    },
    creatives: {
      immediate: true,
      handler() {},
      deep: true,
    },
  },
  computed: {
    nextDisable() {
      const hasControl1 = this.creatives.some(
        (obj) => obj.CellType === "Control" && obj.stimID !== 0
      );
      const hasControl2 = this.creatives.some(
        (obj) => obj.CellType === "Aligned" && obj.stimID !== 0
      );
      const hasControl3 = this.creatives.some(
        (obj) => obj.CellType === "Unaligned" && obj.stimID !== 0
      );
      return (
        !(
          this.creatives.length > 0 &&
          this.creatives.findIndex((stim) => stim.stimID === 0) === -1
        ) ||
        (!(hasControl1 && hasControl2 && hasControl3) &&
          this.$route.meta.surveyTypeID === 102)
      );
    },
    prevDisable() {
      if (this.showConfigPanel) {
        return !(
          this.creatives.length > 0 &&
          this.creatives.findIndex((stim) => stim.stimID === 0) === -1
        );
      } else {
        return false;
      }
    },
  },
  methods: {
    configUpdate(status) {
      switch (status.key) {
        case "name":
          this.configStatus.nameReady = status.completed;
          break;
        case "stage":
          this.configStatus.stageReady = status.completed;
          break;
        case "storyboard":
          this.configStatus.storyboardReady = status.completed;
          break;
        case "thumb":
          this.configStatus.thumbReady = status.completed;
          break;
        case "brandLogo":
          this.configStatus.brandLogo = status.completed;
          break;
        case "mainVideoURL":
          this.configStatus.mainVideoURL = status.completed;
          break;
        case "cellType":
          this.configStatus.cellType = status.completed;
          break;
      }
      if (!this.showUploadPanel) {
        this.configQuizs();
      }
    },
    addNewStim() {
      if (!this.tabDisabled) {
        this.showUploadPanel = true;
        this.curTabIdex = -1;
        this.stimID = 0;
      }
    },
    stimDeleted() {
      this.tabDisabled = false;
      this.creatives.splice(this.curTabIdex, 1);
      // this.editableSurvey.StimDetails.splice(this.curTabIdex, 1);

      this.showUploadPanel = true;
      this.curTabIdex = -1;
      this.stimID = 0;
      this.priceCalculate();
      this.getSurveyTotalPrice();
    },
    stimSaved(editMode) {
      this.tabDisabled = false;
      if (editMode !== "edit" || this.$route.meta.surveyTypeID === 102) {
        this.addNewStim();
      }
      this.priceCalculate();
      this.getSurveyTotalPrice();
    },
    priceCalculate() {
      let videos = [{ duration: 100 }];
      if (this.creatives.length !== 0) {
        videos = this.creatives.map((item) => {
          return { duration: item.AdDuration };
        });
      }

      let surveyPriceSetData = {
        Stims: videos,
      };
      let currentSurveyPriceData = jsUtils.getSurveyPrice();
      jsUtils.setSurveyPrice({
        ...currentSurveyPriceData,
        ...surveyPriceSetData,
      });

      mutations.setSurveyPriceStims(videos);
    },
    viewStim(index) {
      this.curTabIdex = index;
      this.showUploadPanel = false;

      this.stimID = this.creatives[index].stimID;
    },
    nextStep() {
      let surveySummary = {
        creativesCount: this.creatives.length,
        creatives: this.creatives.map((item) => {
          return {
            name:
              this.$route.meta.surveyTypeID !== 102
                ? item.vidName
                : item.CellType,
            duration: item.AdDuration,
            coverImage: item.coverImage,
          };
        }),
      };
      let currentSummaryData = jsUtils.getSurveySummaryData();
      jsUtils.setSurveySummaryData({ ...currentSummaryData, ...surveySummary });

      this.$router.push({
        name: this.$route.meta.creativeUploadNextRoute || "vid-brand-strategy",
        params: { id: this.surveyID },
      });
    },
    prevStep() {
      this.$router.push({
        name: this.$route.meta.creativeUploadPevRoute || "vid-setting",
        params: { id: this.surveyID },
      });
    },
    processYTLink(data) {
      this.showUploadPanel = false;
      this.tabDisabled = true;
      // init a new videoObj instance
      // debugger; // eslint-disable-line no-debugger
      let videoObj = {
        link: data.link,
        assetID: data.assetID,
        coverImage: "",
        storyboard: [],
        isYoutube: true,
        vidName: "",
        creativeStage: "",
        keyFrames: [],
        mode: "",
        stimID: 0,
        AdDuration: 0,
        VideoPlayLimit: 10,
        brand: this.projectSummary.brand,
      };
      if (this.$route.meta.brandLogoUpload) {
        videoObj.brandLogoUrl = "";
      }
      if (this.$route.meta.surveyTypeID === 102) {
        videoObj.MainVideoURL = "";
        videoObj.CellType = "";
      }
      this.creatives.push(videoObj);
      this.curTabIdex = this.creatives.length - 1;
    },
    localFileInfoUpdate(data) {
      // this method will be call when the file name is selected and start uploading
      // the file name will be passed first, then the url of video
      this.showUploadPanel = false;
      this.showConfigPanel = true;
      this.tabDisabled = true;

      if (data.url === "") {
        // init videoObj
        let videoObj = {
          link: "",
          coverImage: "",
          storyboard: [],
          isYoutube: false,
          vidName: data.name,
          creativeStage: "",
          keyFrames: [],
          mode: "",
          stimID: 0,
          assetID: data.assetID,
          AdDuration: parseInt(data.AdDuration, 10),
          VideoPlayLimit: 10,
          brand: this.projectSummary.brand,
        };
        if (this.$route.meta.brandLogoUpload) {
          videoObj.brandLogoUrl = "";
        }
        if (this.$route.meta.surveyTypeID === 102) {
          videoObj.MainVideoURL = "";
          videoObj.CellType = "";
        }
        this.creatives.push(videoObj);
        this.curTabIdex = this.creatives.length - 1;
      } else {
        this.creatives[this.curTabIdex].link = data.url;
        this.creatives[this.curTabIdex].assetID = data.assetID;
      }
    },
    uploadQuizs() {
      const reticleQuiz = [
        {
          quizName: "Provide ad + context videos for Control",
          completed: this.creatives.some(
            (obj) => obj.CellType === "Control" && obj.stimID !== 0
          ),
        },
        {
          quizName: "Provide ad + context videos for Aligned content",
          completed: this.creatives.some(
            (obj) => obj.CellType === "Aligned" && obj.stimID !== 0
          ),
        },
        {
          quizName: "Provide ad + context videos for Unaligned content",
          completed: this.creatives.some(
            (obj) => obj.CellType === "Unaligned" && obj.stimID !== 0
          ),
        },
      ];
      if (this.creatives.length === 0) {
        if (this.$route.meta.surveyTypeID !== 102) {
          this.quizs = [
            {
              quizName: this.langObj["s2-upload"].robot1,
              completed: this.creatives.length > 0,
            },
          ];
        } else {
          this.quizs = reticleQuiz;
        }
      } else {
        if (this.$route.meta.surveyTypeID !== 102) {
          this.quizs = [
            {
              quizName: `${this.langObj["s2-upload"]["robot2.1"]} ${this.creatives.length} ${this.langObj["s2-upload"]["robot2.2"]}`,
              completed: this.creatives.length > 0,
              quizTip: this.langObj["s2-upload"].robot3,
            },
          ];
        } else {
          this.quizs = reticleQuiz;
        }
      }
    },
    configQuizs() {
      this.quizs = [
        {
          quizName: this.langObj["s2-name"].robot,
          quizType: "name",
          completed: this.configStatus.nameReady,
        },
        {
          quizName: this.langObj["s2-stage"].robot,
          completed: this.configStatus.stageReady,
        },
        {
          quizName: this.langObj["s2-storyboard"].robot,
          quizType: "storyboard",
          completed: this.configStatus.storyboardReady,
        },
        {
          quizName: this.langObj["s2-upload"].robotBrandLogo,
          quizType: "brandLogo",
          completed: this.configStatus.brandLogo,
        },
      ];

      if (!this.$route.meta.brandLogoUpload) {
        this.quizs = this.quizs.filter(function (quiz) {
          return quiz.quizType !== "brandLogo";
        });
      }

      if (this.$route.meta.surveyTypeID === 102) {
        this.quizs = [
          {
            quizName: "Provide ad + context videos for Control",
            completed: this.creatives.some(
              (obj) => obj.CellType === "Control" && obj.stimID !== 0
            ),
          },
          {
            quizName: "Provide ad + context videos for Aligned content",
            completed: this.creatives.some(
              (obj) => obj.CellType === "Aligned" && obj.stimID !== 0
            ),
          },
          {
            quizName: "Provide ad + context videos for Unaligned content",
            completed: this.creatives.some(
              (obj) => obj.CellType === "Unaligned" && obj.stimID !== 0
            ),
          },
        ];
      }
    },
    stopUpload(assetID) {
      this.$error({
        title: "Video problem detected",
        content: () => (
          <div>
            The video you have uploaded is too long for our standard testing
            framework. <br />
            <br /> Please contact Hotspex support to learn more about engaging
            with our research team and gain access to variety of custom research
            tools and methodologies. <br />
            <br /> In order to proceed with this test, you will need to upload
            video shorter than 480 seconds.
          </div>
        ),
      });
      this.creatives.splice(
        this.creatives.findIndex((el) => el.assetID === assetID),
        1
      );
      this.showUploadPanel = true;
      this.tabDisabled = false;
    },
    newStimId() {
      // console.log("newStimIdnewStimIdnewStimId", this.creatives, data);
    },
  },
  mounted() {
    this.client = jsUtils.getCurClient();
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
  },
  created() {
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
  },
  activated() {
    this.showUploadPanel = true;
    this.surveyTypeID = jsUtils.getSurveyTypeId();
    this.user = jsUtils.getUserInfo();
    this.surveyID = this.$route.params.id;
    this.projectSummary = jsUtils.getSurveySummaryData();
    this.editableSurvey = jsUtils.getEditableSurvey();
    this.stimID = 0;

    if (this.editableSurvey) {
      const videos = this.editableSurvey.StimDetails.map((item) => {
        let videoItem;
        if (this.$route.meta.surveyTypeID !== 102) {
          videoItem = {
            stimID: item.StimID,
            assetID: item.AssetId,
            vidName: item.VideoName,
            link: item.VideoURL,
            mode: item.VideoMode,
            creativeStage: item.CreativeStage,
            coverImage: item.CoverImage,
            surveyID: item.SurveyId,
            storyboard: item.StoryBoard,
            VideoPlayLimit: 10,
            AdDuration: parseInt(item.AdDuration),
            StoryboardURL: item.StoryboardURL,
            brand: item.BRAND,
            brandLogoUrl: item.BrandImageURL,
          };
        } else {
          videoItem = {
            stimID: item.StimID,
            link: item.ad1preroll || item.VideoURL,
            creativeStage: item.CreativeStage,
            coverImage: item.ImageURL || item.CoverImage,
            surveyID: item.SurveyId,
            VideoPlayLimit: 10,
            AdDuration: parseInt(item.AdDuration),
            brand: item.Brand || item.BRAND,
            CellType: item.CellType,
            CellName: item.CellName,
            MainVideoURL: item.MainVideoURL || item.MainVideoURl,
          };
          videoItem.MainVideoURL = videoItem.MainVideoURL.replace(/,/g, "\n");
        }
        return videoItem;
      });

      this.creatives.push(...videos);
    }
    this.getSurveyTotalPrice();
    this.uploadQuizs();
  },
  deactivated() {
    this.creatives = [];
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  svg {
    margin-top: 8px;
  }
}
.videoAds {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow: hidden;
}

.authoring {
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  .questions {
    max-width: 900px;
  }
}

.tabs {
  margin-top: 2em;
  position: relative;
  display: flex;
  column-gap: 8px;

  .tab {
    width: 140px;
    height: 120px;
    background-color: white;
    padding: 10px;
    border: 1px solid var(--lineGrey);
    border-radius: 8px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;

    .icon {
      display: block;
      font-size: 24px;
      color: var(--blue);
      line-height: 1.2;
    }
    .iconDes {
      font-size: 0.8em;
      color: var(--blue);
      font-weight: 500;
    }

    .thumbHolder {
      width: 100%;
      height: 100%;
      // padding-bottom: 10px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }

  .upload,
  .processing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .active {
    background-color: white !important;
    border-bottom: none;
    height: 130px;
    margin-bottom: 0;
    cursor: default;
    border-radius: 8px 8px 0 0;
    z-index: 1;
  }

  .disabled {
    filter: grayscale(1);
    cursor: not-allowed;
    background-color: var(--disabledBG);
    pointer-events: none;
  }
}

.creativeUpload,
.creativeConfig {
  border: 1px solid var(--lineGrey);
  border-radius: 0 0 6px 6px;
  padding: 25px 20px;
  margin-top: -1px;
  background-color: rgba(255, 255, 255, 0.8);
  min-width: 900px;
  @media screen and (max-width: 990px) {
    min-width: auto;
  }
  ::v-deep .stepAuthor {
    padding: 0;
  }
}

.creativeUpload {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 10px;
  position: relative;
  &__stim-limit {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    p {
      background: #fff;
      padding: 3px 10px;
      font-size: 15px;
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
    }
  }
}

.or {
  font-size: 16px;
  align-self: center;
  position: relative;
  span {
    background: #fff;
    z-index: 1;
    position: relative;
    padding: 0 3px;
  }
  &::before {
    position: absolute;
    left: -10px;
    right: -10px;
    content: "";
    top: 50%;
    height: 1px;
    background: var(--lineGrey);
  }
}
</style>
